import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import api from "@/services/api";
import store from './store'
import moment from 'moment'
import VueGtag from "vue-gtag";
import "./scss/main.scss";
import 'bootstrap';

api.interceptors.response.use(
    response => {
        return Promise.resolve(response);
    },
    error => {
        if (error.response.status)
        {
            switch (error.response.status)
            {
                case 401:
                {
                    console.log('401 error - Session expired');
                    
                    store.dispatch('signOut')
                    .then(() => {
                        router.replace('/login');
                    });
                    
                    break;
                }
            }

            return Promise.reject(error);
        }
    }
);

const app = createApp(App);

app.config.globalProperties.$accessLevel = {
    basic: 1,
    sensitive: 2,
    full: 3,
}

app.config.globalProperties.$filters = {
    formatDate(value) {
        return moment(String(value)).format('DD/MM/YYYY')
    },
    formatDateVerbose(value) {
        return moment(String(value)).format('Do MMMM YYYY')
    },
    formatDateVerboseFull(value) {
        return moment(String(value)).format('ddd Do MMMM YYYY')
    },
    formatDateMonthYear(value) {
        return moment(String(value)).format('MMMM YYYY')
    },
    formatDateTime(value) {
        return moment(String(value), 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
    },
    formatTime(value) {
        return moment(String(value), "HH:mm:ss").format('HH:SS')
    },
    formatMoney(value) {
        if(value === null)
        {
            return '';
        }
        
        return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value);
    },
    canAccess(value) {
        return store.state.currentAccessLevel >= value;
    },
}


app
.use(store)
.use(router)
.use(VueGtag, {
    config: { 
        id: process.env.VUE_APP_GOOGLE_ANALYTICS_TAG,
        params: {
            cookie_flags: process.env.VUE_APP_GOOGLE_ANALYTICS_COOKIE_FLAGS,
            cookie_domain: process.env.VUE_APP_GOOGLE_ANALYTICS_COOKIE_DOMAIN,
        },
    }
}, router)
.mount('#app');