import { createStore } from 'vuex'
import api from "@/services/api";

export default createStore({
    state: {
        isSignedIn: !!localStorage.getItem('isSignedIn'),
        currentPracticeId: localStorage.getItem('currentPracticeId'),
        currentPractice: null,
        currentAccessLevel: null,
        user: localStorage.getItem('userId'),
    },
    mutations: {
        SET_IS_SIGNED_IN(state, isSignedIn) {
            state.isSignedIn = isSignedIn;
        },
        SET_CURRENT_PRACTICE_ID(state, currentPracticeId) {
            state.currentPracticeId = currentPracticeId;
        },
        SET_CURRENT_PRACTICE(state, currentPractice) {
            state.currentPractice = currentPractice;
        },
        SET_CURRENT_ACCESS_LEVEL(state, currentAccessLevel) {
            state.currentAccessLevel = currentAccessLevel;
        },
        SET_USER(state, user) {
            state.user = user; 
        },
    },
    actions: {
        signIn(context) {
            return new Promise((resolve) => {
                context.commit('SET_IS_SIGNED_IN', true);
                localStorage.setItem('isSignedIn', true);

                resolve();
            });
        },
        signOut(context) {
            return new Promise((resolve) => {
                localStorage.removeItem('currentPracticeId');
                localStorage.removeItem('isSignedIn');
                localStorage.removeItem('userId');
                context.commit('SET_CURRENT_PRACTICE_ID', null);
                context.commit('SET_CURRENT_PRACTICE', null);
                context.commit('SET_CURRENT_ACCESS_LEVEL', null);
                context.commit('SET_IS_SIGNED_IN', false);
                context.commit('SET_USER', null);

                resolve();
            });
        },
        setCurrentPracticeId(context, currentPracticeId) {
            localStorage.setItem('currentPracticeId', currentPracticeId);
            context.commit('SET_CURRENT_PRACTICE_ID', currentPracticeId);
        },
        setCurrentPractice(context) {
            if(this.state.currentPracticeId)
            { 
                api
                .get('/api/v1/practices/' + this.state.currentPracticeId)
                .then((response) => {
                    context.commit('SET_CURRENT_PRACTICE', response.data.data);
                    context.commit('SET_CURRENT_ACCESS_LEVEL', response.data.data.access_level);
                })
                .catch(() => {
                    console.log('Catch user error');
                });
            }
        },
        fetchPractices: function() {
            return new Promise((resolve, reject) => {
                api
                .get('/api/v1/practices')
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch(() => {
                    reject();
                });
            });
        },
        fetchUser(context) {   
            api
            .get('/api/v1/users/me')
            .then((response) => {
                localStorage.setItem('userId', response.data.data.id);
                context.commit('SET_USER', response.data.data);
            })
            .catch(() => {
                console.log('Catch user error');
            });
        },
    },
    modules: {

    }
})
