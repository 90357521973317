<template>
    <nav class="navbar navbar-expand-none navbar-light bg-fresh-green py-sm-4 py-3 px-sm-4 px-2">
        <div class="container-xxl align-items-stretch">
            <router-link to="/" class="navbar-brand order-0 d-flex align-items-center">
                <img src="../assets/images/logo.png" class="navbar-brand-image" />
            </router-link>
            <div class="d-flex order-1 order-md-2">
                <div class="dropdown ms-1" v-if="currentPractice">
                    <span class="dropdown-text-toggle navbar-option p-2" data-bs-toggle="dropdown">
                        <i class="bi-house-door navbar-icon"></i>
                        <span class="ps-2 d-none d-lg-inline">{{currentPracticeName}}</span>
                    </span>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li class="px-3 py-1 d-lg-none text-truncate">{{currentPracticeName}}</li>
                        <li><router-link class="dropdown-item" to="/practices">Switch practice</router-link></li>
                    </ul>
                </div>
                <div class="dropdown ms-1" v-if="user">
                    <span class="dropdown-text-toggle navbar-option p-2" data-bs-toggle="dropdown">
                        <i class="bi-person-circle navbar-icon"></i>
                        <span class="ps-2 d-none d-lg-inline">{{ user.first_name }} {{ user.last_name }}</span>
                    </span>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li class="px-3 py-1 d-lg-none text-truncate">{{ user.first_name }} {{ user.last_name }}</li>
                        <li><router-link class="dropdown-item" v-if="!['Practices'].includes(this.$route.name)" to="/security">Security</router-link></li>
                        <li><router-link class="dropdown-item" to="/" v-on:click="signOut()">Sign out</router-link></li>
                    </ul>
                </div>
                <span class="ms-1 p-2 d-lg-none navbar-option" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" v-if="currentPractice">
                    <i class="bi-list navbar-icon"></i>
                </span>
            </div>
            <div class="collapse navbar-collapse d-lg-none order-2 order-lg-1" id="navbarSupportedContent" v-if="currentPractice">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'Dashboard' }">
                            <i class="bi bi-speedometer2 pe-2"></i> Dashboard
                        </router-link>
                    </li>
                    <li :class="{ 'nav-item-current': ['Orders', 'PendingOrders', 'Order', 'OrderCreate', 'OrderReturn', 'OrderCreateSend', 'Consignment', 'OrderUpdate'].includes(this.$route.name) }">
                        <router-link :to="{ name: 'Orders' }" class="nav-link">
                            <i class="bi bi-inbox pe-2"></i> Orders
                        </router-link>
                        <ul class="nav flex-column" v-if="['Orders', 'PendingOrders', 'OrderCreateSend', 'OrderUpdate', 'OrderCreate'].includes(this.$route.name)">
                            <li class="sub-nav-item" :class="{ 'nav-item-current': ['Orders'].includes(this.$route.name) }">
                                <router-link :to="{ name: 'Orders' }" class="nav-link">
                                    Accepted
                                </router-link>
                            </li>
                            <li class="sub-nav-item" :class="{ 'nav-item-current': ['PendingOrders'].includes(this.$route.name) }">
                                <router-link :to="{ name: 'PendingOrders' }" class="nav-link">
                                    Requested
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li v-if="currentPractice && currentPractice.allowed_collections" :class="{ 'nav-item-current': ['Collections', 'CollectionCreate', 'CollectionsHistoric', 'CollectionCreate'].includes(this.$route.name) }">
                        <router-link :to="{ name: 'Collections' }" class="nav-link">
                            <i class="bi bi-truck pe-2"></i> Collections
                        </router-link>
                        <ul class="nav flex-column" v-if="['Collections', 'CollectionsHistoric', 'CollectionCreate'].includes(this.$route.name)">
                            <li class="sub-nav-item" :class="{ 'nav-item-current': ['Collections'].includes(this.$route.name) && (this.$route.params.state === undefined ||  this.$route.params.state == 'future') }">
                                <router-link :to="{ name: 'Collections' }" class="nav-link">
                                    Upcoming
                                </router-link>
                            </li>
                            <li class="sub-nav-item" :class="{ 'nav-item-current': ['CollectionsHistoric'].includes(this.$route.name) && this.$route.params.state == 'past' }">
                                <router-link :to="{ name: 'CollectionsHistoric' }" class="nav-link">
                                    Historical
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item" v-if="$filters.canAccess($accessLevel.sensitive)">
                        <router-link class="nav-link" :to="{ name: 'Billing' }">
                            <i class="bi bi-currency-pound pe-2"></i> Billing
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="$filters.canAccess($accessLevel.sensitive)">
                        <router-link class="nav-link" :to="{ name: 'Reports' }">
                            <i class="bi bi-files pe-2"></i> Reports
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="$filters.canAccess($accessLevel.full)">
                        <router-link class="nav-link" :to="{ name: 'Users' }">
                            <i class="bi bi-person pe-2"></i> Users
                        </router-link>
                    </li>
                    <li v-if="currentPractice && currentPractice.access_level === 3" :class="{ 'nav-item-current': ['Settings', 'SettingsPracticeDetails', 'SettingsPracticePreferences'].includes(this.$route.name) }">
                        <router-link :to="{ name: 'Settings' }" class="nav-link">
                            <i class="bi bi-gear pe-2"></i> Settings
                        </router-link>
                        <ul class="nav flex-column" v-if="['Settings', 'SettingsPracticeDetails', 'SettingsPracticePreferences'].includes(this.$route.name)">
                            <li class="sub-nav-item" :class="{ 'nav-item-current': ['SettingsPracticeDetails'].includes(this.$route.name) }">
                                <router-link :to="{ name: 'SettingsPracticeDetails' }" class="nav-link">
                                    Address
                                </router-link>
                            </li>
                            <li class="sub-nav-item" :class="{ 'nav-item-current': ['SettingsPracticePreferences'].includes(this.$route.name) }">
                                <router-link :to="{ name: 'SettingsPracticePreferences' }" class="nav-link">
                                    Preferences
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'Help' }">
                            <i class="bi bi-question-circle pe-2"></i> Help
                        </router-link>
                    </li>
                </ul>
            </div>   
        </div>
    </nav>
</template>

<style scoped lang="scss">
    .dropdown-text-toggle
    {
        &::after
        {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: middle;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
        }
    }

    .navbar-option
    {
        user-select: none;
        cursor: pointer;
        display: inline-block;
    }

    .dropdown-menu 
    {
        max-width: 65vw;
    }

    .navbar-icon
    {
        vertical-align: middle;
        font-size: 1.5em;
    }

    .navbar-brand-image
    {
        height: 50px;
    }
    
    @include media-breakpoint-down(lg)
    {
        .navbar-collapse
        {
            border-top: 1px solid #EEEEEE;
            border-bottom: 1px solid #EEEEEE;
            position: absolute;
            left: 0px;
            right: 0px;
            top: 100%;
            background: #FFFFFF;
            padding: 10px 20px;
        }
    }

    @include media-breakpoint-down(sm)
    {
        .navbar-icon
        {
            font-size: 1.1em;
        }

        .navbar-brand-image
        {
            height: 27px;
        }
    }

</style>

<script>
    import { Collapse } from 'bootstrap';
    import api from "@/services/api";

    export default {
        computed: {
            user () {
                return this.$store.state.user
            },
            currentPractice () {
                return this.$store.state.currentPractice;
            },
            currentPracticeName () {
                if(this.$store.state.currentPractice === null)
                {
                    return '';
                }

                return this.$store.state.currentPractice.company_name;
            },
        },
        methods: {
            signOut: function () {
                api.post('/api/v1/logout')
                .finally(() => {
                    this.$store.dispatch('signOut');
                    this.$router.push('/login');
                });
            }
        },
        mounted () {
            this.$store.dispatch('fetchUser');
            this.$store.dispatch('setCurrentPractice');
        },
        watch: {
            '$route' () {
                if(this.currentPractice)
                {
                    new Collapse(document.querySelector("#navbarSupportedContent"), {toggle: false}).hide();
                }
            }
        },
    }
</script>
