import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import store from '../store'

const routes = [
	{
		path: '/',
		name: 'Dashboard',
		component: Dashboard,
	},
	{
		path: '/login',
		name: 'Signin',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "signin" */ '../views/Signin.vue'),
	},
	{
		path: '/login/:code',
		name: 'SigninOTP',
		component: () => import(/* webpackChunkName: "signinOTP" */ '../views/SigninOTP.vue'),
		props: true,
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: () => import(/* webpackChunkName: "resetPassword" */ '../views/ResetPassword.vue'),
	},
	{
		path: '/reset-password/:code',
		name: 'ChangePassword',
		component: () => import(/* webpackChunkName: "changePassword" */ '../views/ChangePassword.vue'),
		props: true,
	},
	{
		path: '/invitation/:code',
		name: 'Register',
		component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
		props: true,
	},
	{
		path: '/practices',
		name: 'Practices',
		component: () => import(/* webpackChunkName: "practices" */ '../views/Practices.vue'),
	},
	{
		path: '/orders',
		name: 'Orders',
		component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue'),
	},
	{
		path: '/orders-requested',
		name: 'PendingOrders',
		component: () => import(/* webpackChunkName: "pendingOrders" */ '../views/PendingOrders.vue'),
	},
	{
		path: '/orders/create',
		name: 'OrderCreate',
		component: () => import(/* webpackChunkName: "orderCreate" */ '../views/OrderCreate.vue'),
	},
	{
		path: '/orders/:id',
		name: 'Order',
		component: () => import(/* webpackChunkName: "order" */ '../views/Order.vue'),
		props: true,
	},
	{
		path: '/orders/:id/send',
		name: 'OrderCreateSend',
		component: () => import(/* webpackChunkName: "orderCreateSend" */ '../views/OrderCreateSend.vue'),
		props: true,
	},
	{
		path: '/orders/:id/update',
		name: 'OrderUpdate',
		component: () => import(/* webpackChunkName: "orderUpdate" */ '../views/OrderUpdate.vue'),
		props: true,
	},
	{
		path: '/orders/:id/aligner-update',
		name: 'AlignerOrderUpdate',
		component: () => import(/* webpackChunkName: "orderUpdate" */ '../views/AlignerOrderUpdate.vue'),
		props: true,
	},
	{
		path: '/orders/:id/return',
		name: 'OrderReturn',
		component: () => import(/* webpackChunkName: "orderReturn" */ '../views/OrderReturn.vue'),
		props: true,
	},
	{
		path: '/consignments/:id',
		name: 'Consignment',
		component: () => import(/* webpackChunkName: "order" */ '../views/Consignment.vue'),
		props: true,
	},
	{
		path: '/collections',
		name: 'Collections',
		component: () => import(/* webpackChunkName: "collections" */ '../views/Collections.vue'),
		props: { state: 'future' },
	},
	{
		path: '/collections-historic',
		name: 'CollectionsHistoric',
		component: () => import(/* webpackChunkName: "collections" */ '../views/Collections.vue'),
		props: { state: 'past' },
	},
	{
		path: '/collections/create',
		name: 'CollectionCreate',
		component: () => import(/* webpackChunkName: "collectionCreate" */ '../views/CollectionCreate.vue'),
	},
	{
		path: '/accounts',
		name: 'Accounts',
		component: () => import(/* webpackChunkName: "accounts" */ '../views/Accounts.vue'),
	},
	{
		path: '/billing',
		name: 'Billing',
		component: () => import(/* webpackChunkName: "billing" */ '../views/Billing.vue'),
	},
	{
		path: '/swiftplus',
		name: 'SwiftPlus',
		component: () => import(/* webpackChunkName: "swiftplus" */ '../views/SwiftPlus.vue'),
	},
	{
		path: '/swiftplus/:year/:month',
		name: 'SwiftPlusStatement',
		component: () => import(/* webpackChunkName: "swiftplusstatement" */ '../views/SwiftPlusStatement.vue'),
		props: true,
	},
	{
		path: '/accounts/:id',
		name: 'Account',
		component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
		props: true,
	},
	{
		path: '/accounts/:id/statements',
		name: 'Statements',
		component: () => import(/* webpackChunkName: "statements" */ '../views/Statements.vue'),
		props: true,
	},
	{
		path: '/accounts/:id/exports',
		name: 'AccountExports',
		component: () => import(/* webpackChunkName: "statements" */ '../views/AccountExports.vue'),
		props: true,
	},
	{
		path: '/accounts/:account_id/transactions/:id',
		name: 'Transaction',
		component: () => import(/* webpackChunkName: "transaction" */ '../views/Transaction.vue'),
		props: true,
	},
	{
		path: '/reports',
		name: 'Reports',
		component: () => import(/* webpackChunkName: "transaction" */ '../views/Reports.vue'),
		props: false,
	},
	{
		path: '/reports/invoices',
		name: 'Invoices',
		component: () => import(/* webpackChunkName: "transaction" */ '../views/Invoices.vue'),
		props: false,
	},
	{
		path: '/reports/patient-statements',
		name: 'PatientStatements',
		component: () => import(/* webpackChunkName: "transaction" */ '../views/PatientStatements.vue'),
		props: false,
	},
	{
		path: '/reports/exports',
		name: 'Exports',
		component: () => import(/* webpackChunkName: "transaction" */ '../views/Exports.vue'),
		props: false,
	},
	{
		path: '/downloads',
		name: 'Downloads',
		component: () => import(/* webpackChunkName: "downloads" */ '../views/Downloads.vue'),
		props: false,
	},
	{
		path: '/shade-match',
		name: 'ShadeMatch',
		component: () => import(/* webpackChunkName: "shadeMatch" */ '../views/ShadeMatch.vue'),
		props: false,
	},
	{
		path: '/security',
		name: 'Security',
		component: () => import(/* webpackChunkName: "security" */ '../views/Security.vue'),
		props: false,
	},
	{
		path: '/users',
		name: 'Users',
		component: () => import(/* webpackChunkName: "billing" */ '../views/UserAdmin/Users.vue'),
		props: false,
	},
	{
		path: '/invitations',
		name: 'Invitations',
		component: () => import(/* webpackChunkName: "billing" */ '../views/UserAdmin/Invitations.vue'),
		props: false,
	},
	{
		path: '/user/view/:id',
		name: 'User',
		component: () => import(/* webpackChunkName: "billing" */ '../views/UserAdmin/UserView.vue'),
		props: true,
	},
	{
		path: '/invitation/view/:id',
		name: 'Invitation',
		component: () => import(/* webpackChunkName: "billing" */ '../views/UserAdmin/InvitationView.vue'),
		props: true,
	},
	{
		path: '/invite',
		name: 'Invite',
		component: () => import(/* webpackChunkName: "billing" */ '../views/UserAdmin/Invite.vue'),
	},
	{
		path: '/help',
		name: 'Help',
		component: () => import(/* webpackChunkName: "security" */ '../views/Help.vue'),
		props: false,
	},
	{
		path: '/settings',
		name: 'Settings',
		component: () => import(/* webpackChunkName: "security" */ '../views/Settings.vue'),
		props: ['preferences'],
	},
	{
		path: '/vouchers',
		name: 'Vouchers',
		component: () => import(/* webpackChunkName: "security" */ '../views/Vouchers.vue'),
		props: false,
	},
	{
		path: '/settings/practice-details',
		name: 'SettingsPracticeDetails',
		component: () => import(/* webpackChunkName: "security" */ '../views/SettingsPracticeDetails.vue'),
		props: false,
	},
	{
		path: '/settings/practice-preferences',
		name: 'SettingsPracticePreferences',
		component: () => import(/* webpackChunkName: "security" */ '../views/SettingsPracticePreferences.vue'),
		props: false,
	},
	{
		path: '/return-label',
		name: 'ReturnLabel',
		component: () => import(/* webpackChunkName: "security" */ '../views/ReturnLabel.vue'),
		props: false,
	},
	{
		path: '/treatment-plan',
		name: 'TreatmentPlan',
		component: () => import(/* webpackChunkName: "security" */ '../views/Iframe.vue'),
		props: false,
	},
	{
		// will match everything
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),

	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes, 
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { top: 0 }
		}
	},
});

router.beforeEach((to, from, next) => {
	if (!['Signin', 'SigninOTP', 'Register', 'ResetPassword', 'ChangePassword', 'NotFound'].includes(to.name) && !store.state.isSignedIn)
	{
		// The user is not signed in, but is attempting to access a secure page
		next({ name: 'Signin' })
	}
	else if (['Signin', 'SigninOTP', 'Register', 'ResetPassword', 'ChangePassword'].includes(to.name) && store.state.isSignedIn)
	{
		// The user is already signed in, but is attempting to access an insecure page
		next({ name: 'Dashboard' })
	}
	else if (!['Practices'].includes(to.name) && store.state.isSignedIn && !store.state.currentPracticeId)
	{
		// The user is already signed in, but does not have a current practice
		next({ name: 'Practices' })
	}
	else
	{
		// Default behavior otherwise.
		next();
	}
});

export default router
