<template>
    <router-link v-if="voucherCount > 0" :to="{name: 'Vouchers'}" class="quick-link action-link col-md-6 mb-4">
    <div class="icon-column">
        <div class="action-icon">
            {{ voucherCount }}
        </div>
    </div>
        <div class="text">
            <div><strong>Vouchers</strong></div>
            <div class="text-description">
                You have {{ voucherCount }} voucher<span v-if="voucherCount > 1">s</span> to use.
            </div>
        </div>

        <div class="arrow"><img src="@/assets/images/arrow.png" /></div>
    </router-link>
</template>

<style scoped lang="scss">
.action
{
    padding-bottom: 20px;

    .action-link
    {
        display: flex;
        align-items: center;
    }

    .action-icon
    {
        aspect-ratio: 1/1;
        line-height: 0;
        font-size: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        width: 70px;
    }
}

.quick-link
{
    text-decoration: none;
    display: flex;

    .icon-column
    {
        display: flex;
        flex-direction: column;
        margin-right: $spacer;
    }

    .icon
    {
        background: $swift-lighter-grey;
        border-radius: 10px;
        aspect-ratio: 1/1;
        font-size: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
    }

    .text
    {
        flex-grow: 1;
        margin-right: $spacer;
    }

    .text-description
    {
        line-height: 1.3em;
    }

    .arrow
    {
        img
        {
            width: 30px;
            margin-top: 10px;
        }
    }

    &:hover
    {
        .icon
        {
            background: $swift-light-grey;
            transition: 0.5s;
        }
    }
}
</style>

<script>
import api from "@/services/api";

export default {
    components: {
    },
    data() {
        return {
            voucherSection: {
                voucher: null,
            },
            vouchers:null,
            accountSection: {
                account_id: null,
            },
            accounts: null,
            productsSection: {
                products: [],
            },
            voucherCount: null
        }
    },
    mounted() {
        this.fetchVouchers();
    },
    methods: {
        fetchVouchers: function() {
            this.vouchers = [];
            //for somereason this.$store.state.user is the user ID sometimes :/
            // other times its a user Object :/
            let userId = this.$store.state.user;
            if(isNaN(this.$store.state.user)){
                userId = this.$store.state.user.id;
            }

        api
            .get('/api/v1/vouchersByPractice', {
              params: {
                practice_id: this.$store.state.currentPracticeId,
                user_id: userId,
              }
            })
            .then((response) => {
              this.vouchers = response.data;
              this.voucherCount = this.vouchers.voucherCount;
              this.$emit('voucher', this.vouchers.voucherCount);
            })
            .catch(() => {
              console.log('Catch voucher error');
            });
    },
  }
}

</script>