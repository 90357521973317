<template>
    <div class="page-section" :class="{ 'mb-4 pb-5': this.isLastSection == 'false', 'page-section-last': this.isLastSection == 'true'}">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            isLastSection: {
                default: 'false',
                required: false,
            },
        },
    }
</script>

<style scoped lang="scss">
    .page-section
    {
        margin-bottom: 0px;
        border-bottom: 2px solid $swift-lighter-grey;
    }

    .page-section-last
    {
        border-bottom: 0px;
    }
</style>