<template>
    <Navbar v-if="isSignedIn && !['TreatmentPlan'].includes(this.$route.name)" />
    <div v-if="isSignedIn && !['Practices', 'TreatmentPlan'].includes(this.$route.name)" class="d-flex flex-grow-1">
        <div class="container-xxl d-flex flex-column flex-grow-1">
            <div class="row flex-grow-1">
                <div class="col-lg-3 col-xxl-2 d-none d-lg-block nav-column">
                    <div class="py-4 pe-3">
                        <Nav />
                    </div>
                </div>
                <div class="col-lg-9 col-xxl-10 col-12">
                    <div class="py-4 px-2 px-lg-3">
                        <router-view />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isSignedIn && ['Practices'].includes(this.$route.name)" class="d-flex flex-grow-1">
        <div class="container-xxl">
            <div class="py-4 px-2">
                <router-view />
            </div>
        </div>
    </div>
    <div v-if="isSignedIn && ['TreatmentPlan'].includes(this.$route.name)" style="height:100vh">
        <router-view />
    </div>
    <router-view class="px-4" v-if="!isSignedIn" />
    <LiveChatWidget
        license="15183837"
        visibility="minimized"
        :customerName="user ? this.$store.state.user.first_name + ' ' + this.$store.state.user.last_name : null"
        :customerEmail="user ? this.$store.state.user.email : null"
        :sessionVariables="user ? { User_ID: this.$store.state.user.id } : null"
    />
</template>

<script>
    import Navbar from '@/components/Navbar.vue';
    import Nav from '@/components/Nav.vue';
    import { LiveChatWidget } from "@livechat/widget-vue";

    export default {
        components: {            
            Navbar,
            Nav,
            LiveChatWidget
        },
        computed: {
            isSignedIn () {
                return this.$store.state.isSignedIn;
            },
            user () {
                return this.$store.state.user
            },
        },
    }
</script>

<style scoped lang="scss">
    .nav-column
    {
        border-right: 3px solid $swift-lighter-grey;
    }
</style>

<style lang="scss">
    body
    {
        min-height: 100vh;
    }

    #app
    {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
</style>