<template>
    <h2 class="h5 mb-3 page-section-heading">
        <slot></slot>
    </h2>
</template>

<script>
    export default { }
</script>

<style scoped lang="scss">
    .page-section-heading
    {
        letter-spacing: -0.05rem;
    }
</style>

